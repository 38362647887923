.coaching-image {
  background-image: url('../src/assets/img/coaching.jpg');
}

.contact-image {
  background-image: url('../src/assets/img/home1.jpg');
}

.flex {
  display: flex;
}

.fs-14 {
  font-size: 12px;
}

.form-messages {
  margin: 15px 0px;
}

.fs-30 {
  font-size: 30px;
}

.headshot-image {
  background-image: url('../src/assets/img/abheadshot.jpg');
}

.home-buying-image {
  background-image: url('../src/assets/img/homebuying.jpg');
}

.logo-img {
  width: 50px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.marketing-image {
  background-image: url('../src/assets/img/marketing.jpg');
}

.about-card-image {
  background-image: url('../src/assets/img/logo.png');
}

.rrg-blue {
  color: #110A70;
}

.rrg-btn-blue {
  background-color: #110A70 !important;
  color:white !important;
}

.rrg-btn-blue:hover {
  background-color: white !important; 
  color: #110A70 !important;
}